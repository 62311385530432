<template>
   <div class="container teamReport">
        <div class="row header">
            <i @click="goBack" class="fa fa-angle-left back-button"></i>
            <p class="attendance" style="margin-left: 30% !important;">Attendance History</p>
        </div>
        <div data-v-f8374830="" class="dailySignInRecord__container-wrapper" v-for="row in rechargerecord" :key="row.id">
        <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content">
            <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content-left">
                <h1 data-v-f8374830="">Continuous attendance {{ row.day }} Day</h1>
                <span data-v-f8374830="">{{ row.created_at }}</span>
            </div>
            <div data-v-f8374830="" class="dailySignInRecord__container-wrapper__content-right">
                <img data-v-f8374830="" alt="" class="" data-origin="../assets/png/coin-294b6998.png" src="../assets/png/coin-294b6998.png">
                <span data-v-f8374830="">{{ row.amount }}</span>
            </div>
        </div>
        </div><br>
        <center>No More</center>
   </div>
</template>
 <script>
    import axios from 'axios';
     export default {
         name: 'DailyHistoryView',
         data(){
             return{
                currentTab: 'tab1',
                userdetails: {
                   username: null,
                   total: 0,
                   days: 0,
                },
                rechargerecord: {},
             }
         },
         beforeCreate: function () {
           if (localStorage.getItem('username') == null)
               this.$router.push('Login');
          },
          created: function() {
             this.getUserdetails();
          },
         methods:{
          goBack() {
             window.history.back();
          },
          pop(message) {
                document.getElementById("snackbar").innerHTML = message;
                document.getElementById("snackbar").style.display = "";
                setTimeout(function () { document.getElementById("snackbar").style.display = "none"; }, 1500);
            },
          getUserdetails(){
            this.userdetails.username = localStorage.getItem('username');
            axios.get('https://manager.tcgame.online/trova/src/api/bet.php?action=signinrec&user=' + this.userdetails.username ).then((res) => {
                this.rechargerecord = res.data;
                console.log(this.rechargerecord);
            }).catch((err) => {
                console.log(err);
            })
          },
          setTab(tab) {
                  this.currentTab = tab;
               },
         }
     }
 </script>
 <style>
    .attendance{
        margin-left: 35% !important;
    }
    .dailySignIn__container-hero { 
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    height: 14.53333rem;
    width: 107.6%;
    margin-left: -3.8%;
    margin-bottom: .42667rem;
    padding: 0 .4rem .26667rem;
    background: url(../assets/png/headerBg-c5504bca.png) no-repeat center/cover,#F54545 no-repeat center/cover
}

.dailySignIn__container-hero__header {
    color: #fff
}

.dailySignIn__container-hero__header h1 {
    font-size: 1.43333rem
}

.dailySignIn__container-hero__header h1:last-of-type {
    margin-bottom: 0;
    font-size: 1.29667rem;
    font-weight: 700
}

.dailySignIn__container-hero__header p {
    font-size: .62rem;
    margin-bottom: .37333rem
}

.dailySignIn__container-hero__header p:first-of-type {
    width: 10.33333rem;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden
}

.dailySignIn__container-hero__header p:last-of-type {
    margin-bottom: .08rem
}

.dailySignIn__container-hero__header div {
    display: inline-block;
    width: 9rem;
    margin-bottom: .21333rem;
    padding: .21333rem .41333rem .21333rem .14667rem;
    color: #f95959;
    font-size: .77333rem;
    line-height: 1.22rem;
    background: #fff;
    -webkit-clip-path: polygon(100% 0,90% 50%,100% 100%,0 100%,0 0);
    clip-path: polygon(100% 0,90% 50%,100% 100%,0 100%,0 0);
    word-break: break-all
}

.dailySignIn__container-hero__header div span {
    margin-inline:.13333rem;font-size: .93333rem;
    font-weight: 600;
    line-height: 1
}

.dailySignIn__container-hero__footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-top: .93333rem;
    margin-bottom: 0.6rem;
}

.dailySignIn__container-hero__footer button {
    width: 8rem;
    height: 1.8rem;
    padding: .18667rem 0;
    color: #fff;
    font-size: .74667rem;
    font-weight: 700;
    line-height: .32rem;
    border: none;
    border-radius: 9rem;
    background: -webkit-linear-gradient(top,#FFBD40 0%,#FF7F3D 100%);
    background: linear-gradient(180deg,#FFBD40 0%,#FF7F3D 100%);
    cursor: pointer
}

.dailySignIn__container-content__wrapper[data-v-f1e983bd] {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-items: center;
    gap: .5rem
}

.dailySignIn__container-content__wrapper-block[data-v-f1e983bd] {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 1rem;
    width: 5.98667rem;
    height: 6.93333rem;
    background: var(--bg_color_L2);
    border-radius: .73333rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 15px;
}

.dailySignIn__container-content__wrapper-block__header[data-v-f1e983bd] {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex
}

.dailySignIn__container-content__wrapper-block__header img[data-v-f1e983bd] {
    width: 4.98667rem;
    height: 2rem;
    visibility: hidden
}

.dailySignIn__container-content__wrapper-block__header span[data-v-f1e983bd] {
    position: absolute;
    top: calc(50% - .02667rem);
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    color: var(--text_color_L1);
    font-size: .82667rem;
    line-height: 1
}

.dailySignIn__container-content__wrapper-block>img[data-v-f1e983bd] {
    width: 3.06667rem;
    height: 3.06667rem;
    margin-block:.18667rem .27773rem}

.dailySignIn__container-content__wrapper-block>span[data-v-f1e983bd] {
    color: var(--text_color_L2);
    font-size: .74667rem
}

.dailySignIn__container-content__wrapper-block img[data-v-f1e983bd] {
    max-width: 100%
}

.dailySignIn__container-content__wrapper-block[data-v-f1e983bd]:last-of-type {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    grid-column: 1/-1;
    background: var(--bg_color_L2)
}

.dailySignIn__container-content__wrapper-block:last-of-type .lastImage[data-v-f1e983bd] {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 8.66667rem;
    background-color: transparent;
    height: 5.93333rem
}

.dailySignIn__container-content__wrapper-block:last-of-type .lastImage img[data-v-f1e983bd] {
    width: 7.57333rem;
    height: 5.45333rem
}

.dailySignIn__container-content__wrapper-block:last-of-type>div[data-v-f1e983bd] {
    position: absolute;
    top: 50%;
    right: 1.8rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    gap: 1rem
}

.dailySignIn__container-content__wrapper-block:last-of-type>div>span[data-v-f1e983bd]:first-of-type {
    position: relative;
    color: var(--text_color_L1);
    font-size: .92667rem
}

.dailySignIn__container-content__wrapper-block:last-of-type>div>span[data-v-f1e983bd]:first-of-type:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -.53333rem;
    -webkit-transform: translate(-100%,-50%);
    transform: translate(-100%,-50%);
    display: block;
    width: .32rem;
    height: .02667rem;
    background: -webkit-linear-gradient(right,#C0C4DC 0%,rgba(192,196,220,0) 100%);
    background: linear-gradient(270deg,#C0C4DC 0%,rgba(192,196,220,0) 100%)
}

.dailySignIn__container-content__wrapper-block:last-of-type>div>span[data-v-f1e983bd]:first-of-type:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -.53333rem;
    -webkit-transform: translate(100%,-50%);
    transform: translate(100%,-50%);
    display: block;
    width: .32rem;
    height: .02667rem;
    background: -webkit-linear-gradient(right,#C0C4DC 0%,rgba(192,196,220,0) 100%);
    background: linear-gradient(270deg,#C0C4DC 0%,rgba(192,196,220,0) 100%)
}

.dailySignIn__container-content__wrapper-block:last-of-type>div>span[data-v-f1e983bd]:last-of-type {
    color: var(--text_color_L2);
    font-size: .9rem
}

.dailySignIn__container-content__wrapper-block.signed[data-v-f1e983bd] {
    background: url(../assets/png/Signed-dd8900d1.png) no-repeat;
    background-position: center;
    background-size: 96% 100%
}

.dailySignIn__container-content__wrapper-block.signed img[data-v-f1e983bd] {
    visibility: visible
}

.dailySignIn__container-content__wrapper-block.signed span[data-v-f1e983bd] {
    color: #fff!important
}

.dailySignIn__container-content__wrapper-block.signed[data-v-f1e983bd]:last-of-type {
    background: url(../assets/png/day7BgActive-0b574f89.png) no-repeat;
    background-position: center;
    background-size: 100% 100%
}

.dailySignIn__container-content__footer[data-v-f1e983bd] {
    width: 100%;
    height: 2.06667rem;
    margin-top: 1.45333rem;
    padding: 0 1.13333rem;
    text-align: center;
    margin-bottom: 3rem;
}

.dailySignIn__container-content__footer button[data-v-f1e983bd] {
    width: 100%;
    height: 100%;
    padding-block:.10667rem;color: var(--text_color_L4);
    font-size: 1rem;
    border: none;
    border-radius: 9rem;
    background: var(--main_gradient-color)
}

.dailySignIn__container-content__footer button[data-v-f1e983bd]:active {
    background: var(--main_gradient-color)
}


@media screen and (max-width: 500px) {
    .dailySignIn__container-hero{
        width: 108.8%;
        margin-left: -4.4%;
    }
}
</style>